<template>
  <div>
    <GenericTable
      ref="lista"
      name="Tipos de Defeito de Material"
      title="Tipos de Defeito de Material"
      add-route="tipoDefeitoMaterialAdicionar"
      edit-route="tipoDefeitoMaterialEditar"
      :nofilters="true"
      :cols="['Nome', 'Descrição']"
      :cols-name="['nome', 'descricao']"
      :cols-map="i => [i.nome, i.descricao]"
      :route="tipoDefeitoMaterialRoute"
      :permissionsToWrite="['rw_material']"
      idKey="id_tipo_defeito_material"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      tipoDefeitoMaterialRoute: 'tipoDefeitoMaterial',
    };
  },
};
</script>
